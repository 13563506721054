import './App.css';

import { Header } from "./header/header.js";
import { Content } from "./content/content.js";
import { Footer } from "./footer/footer.js";
import { Registration } from './registration/registration.js';

import { ScrollProvider } from './ScrollContext';

import {Route, Routes, BrowserRouter as Router} from "react-router-dom"


function App() {




  return (

    <ScrollProvider >

      <div className='App'>
      <Router>
        <Routes> <Route path='/' element={<Header />} /> </Routes>
        <Routes> <Route path='/' element={<Content />} /> </Routes>

        <Routes> <Route path='/' element={<Footer />} /></Routes>

        <Routes> <Route path='/registration' element={<Registration />} /></Routes>

      </Router>



      </div>

      </ScrollProvider>

  );
}

export default App;
