import registrationImage from "../images/registeration.webp";
import "../registration/registration.css"
import logo from "../images/main-header.webp"

import phoneImage from "../images/Phone.png"
import userImage from "../images/User.png"
import emailImage from "../images/Email.png"
import addressImage from "../images/Address.png"

import React, { useRef, useState, useEffect } from 'react';
import emailjs from '@emailjs/browser';

import arrow from "../images/Arrow 3.png"

import { Link } from 'react-router-dom';

import facebookIcon from "../images/facebookIcon.png"
import instagramIcon from "../images/instagramIcon.png"
import youtubeIcon from "../images/youtubeIcon.png"
import snapchatIcon from "../images/snapchatIcon.png"
import tiktokIcon from "../images/tiktokIcon.png"
import twitterXIcon from "../images/twitterXIcon.png"



export const Registration = () => {
    const [messagesent, usemessagesent] = useState(false)
    const form = useRef();

    const sendEmail  = (e) => {
      e.preventDefault();
  
      emailjs.sendForm('service_fv031td', 'template_bt0wkcx', form.current, 'j81jiCczsLIyLJjZr')
        .then( async (result) => {
            console.log(result.text);
            usemessagesent(true);

            const myButton = document.getElementById('myButtonId');
            if (myButton) {
                myButton.disabled = true;
                myButton.classList.replace("submitButton", "disabledSubmitButton");
              }

              
                

        }, (error) => {
            console.log(error.text);
        });
    };



    return (
        <>
       
            <div className="flex justify-between bg-black absolute hidden md:flex" style={{width: '100%', height: "100%"}}>
                

            <div className="left-blue-color absolute"></div>

            
              <img src={registrationImage} style={{width: "100%", objectFit: "cover", position: "relative"}}/>
              
            <div className="right-blue-color absolute"></div>
            </div>

            
            <div className="lg:flex justify-center bg-black">
            <div className=" relative pt-0 md:pl-60 md:pr-60  " style={{width: '100%'}}>
                

            <img src={registrationImage} style={{width: "100%", height: "1200px", objectFit: "cover", position: "absolute"}} className="md:hidden"/>

            {messagesent && 
            <>{Number(localStorage.getItem("myValue")) === 0 ? <div className="fixed sentMessage bg-black mt-14 text-left"> Message sent, we will contact you as soon as possible. </div> : 
            
            <div className="flex justify-end"><div className="fixed text-right sentMessage bg-black mt-14" > .تم ارسال رسالتك، سنتواصل معك بأقرب وقت </div></div>
            }</> }
       
                <div className="flex justify-between " style={{width: "100%"}}>

                    <h2 className={Number(localStorage.getItem("myValue")) === 0 ? "text-white mt-16 order-0 ml-10 lg:ml-0" : "text-white mt-16 order-1 mr-10 md:mr-24" }>
                        
                        {Number(localStorage.getItem("myValue")) === 0 ? "Distributor registration  " : "  تسجيل الموزعين " }
                        </h2>
                        
                    <img src={logo} className={Number(localStorage.getItem("myValue")) === 0 ? "w-60 order-1" : "w-60 order-0 md:-ml-10" } />
                        

                </div>

                
                
                <div className="grid md:flex justify-center lg:justify-between" style={{width: "100%",}}>
                    <div className={Number(localStorage.getItem("myValue")) === 0 ? "order-0  " : "  order-1 " }> 
                        <p className={Number(localStorage.getItem("myValue")) === 0 ? "text-white text-4xl italic pa md:w-44 " : "text-white text-4xl italic paArabic md:w-48 md:ml-6 mt-10 md:mt-0 "}>
                            {Number(localStorage.getItem("myValue")) === 0 ? "Glad to see you  " : " كن أحد وكلائنا! " }</p>

                        <p className= {Number(localStorage.getItem("myValue")) === 0 ? "text-white mt-10 text-2xl md:text-left" : "text-white mt-10 text-2xl lg:ml-6 md:text-left"  }  >+9647844000698 </p>
                        <p className= {Number(localStorage.getItem("myValue")) === 0 ? "text-white text-2xl md:text-left" : "text-white text-2xl md:ml-6 md:text-left" } >+9647844000698 </p>

                        <p className= {Number(localStorage.getItem("myValue")) === 0 ? "text-white mt-10 text-2xl md:text-left" : "text-white mt-10 text-2xl md:text-right md:w-48 md:ml-6" } >
                        {Number(localStorage.getItem("myValue")) === 0 ? " Address " : "  العنوان " }
                        </p>

                        <p className= {Number(localStorage.getItem("myValue")) === 0 ? "text-white text-lg  addressPa mt-4 text-left" : "text-white text-lg  addressPa mt-4 text-right md:w-48 md:ml-6"} > 
                        {Number(localStorage.getItem("myValue")) === 0 ? " Baghdad - Al-Mansour - Alamerat St. " : "  بغداد - المنصور - شارع الاميرات " }
                        </p>

                        <p className= {Number(localStorage.getItem("myValue")) === 0 ? "text-white text-lg  addressPa md:-ml-20 mt-4 mb-24" : "text-white text-lg  addressPa mt-14 mr-0 md:mr-20 mb-20"} >
                        {Number(localStorage.getItem("myValue")) === 0 ? " Email: info@whitetigerdrink.com " : "info@whitetigerdrink.com " }</p>
                        <div className={Number(localStorage.getItem("myValue")) === 0 ? "flex -mt-16 justify-center lg:justify-start" : "flex -mt-16 justify-center lg:justify-end md:mr-20"}>
                    <img style={{cursor: "pointer", marginTop: "2px"}} className=" h-5 mr-3" src={facebookIcon} onClick={() => window.location.assign("https://www.facebook.com/whitetiger.iq")} />

                    <img style={{cursor: "pointer"}} className=" h-6 mr-3" src={instagramIcon} onClick={() => window.location.assign("https://www.instagram.com/whitetiger.iq")} />

                    <img style={{cursor: "pointer"}} className=" h-4 mr-3 mt-1" src={youtubeIcon} onClick={() => window.location.assign("https://www.youtube.com/channel/UCgnJpagyQJDHrQLyHdPoiGg")} />

                    <img style={{cursor: "pointer"}} className=" h-6 mr-3" src={snapchatIcon} onClick={() => window.location.assign("https://accounts.snapchat.com/v2/welcome")} />

                    <img style={{cursor: "pointer"}} className=" h-6 mr-3" src={tiktokIcon} onClick={() => window.location.assign("https://www.tiktok.com/@whitetiger.iq")} />

                    <img style={{cursor: "pointer", marginTop: "2px"}} className=" h-5 mr-3" src={twitterXIcon} onClick={() => window.location.assign("https://twitter.com/whiitetigeer")} />
                    </div>
                    </div>



                    <form ref={form} style={{zIndex: "423423141324321"}} onSubmit={sendEmail} className={Number(localStorage.getItem("myValue")) === 0 ? "order-1  lg:-mt-6" : "  order-0 lg:-mt-6"}>
                    <div className="flex mt-6">                            
                            <img src={userImage} className={Number(localStorage.getItem("myValue")) === 0 ? "order-0  h-10 mt-2 ml-3 " : "  order-1 h-10 mt-2 md:ml-3 " }/>
                            <input type="text" name="user_name" className= {Number(localStorage.getItem("myValue")) === 0 ? "mr-4 ml-4 h-12 pl-4 myInput text-left text-white" : "mr-4 ml-4 h-12 pr-4 myInput text-right text-white"}
                            placeholder={Number(localStorage.getItem("myValue")) === 0 ? "  Your name  " : "الاسم الكامل" }  required/>
                        </div>

                        <div className="flex mt-6">                            
                            <img  src={phoneImage} className={Number(localStorage.getItem("myValue")) === 0 ? "order-0 h-10 mt-2 p-1 ml-1" : "order-1 h-10 mt-2 p-1 ml-1" }/>
                            <input  name="user_number" className= {Number(localStorage.getItem("myValue")) === 0 ? "mr-4 ml-4 h-12 pl-4 myInput text-left text-white" : "mr-4 ml-4 h-12 pr-4 myInput text-right text-white"}
                            placeholder={Number(localStorage.getItem("myValue")) === 0 ? "  Phone number  " : "رقم الهاتف" }  required/>
                        </div>


                        <div className="flex mt-6">                            
                        <img src={addressImage} className={Number(localStorage.getItem("myValue")) === 0 ? "order-0  h-10 mt-2 p-1 pb-2" : "order-1 h-10 mt-2 p-1 pb-2" }/>
                            <input type="address" name="user_address" className= {Number(localStorage.getItem("myValue")) === 0 ?  "mr-4 ml-4 h-12 pl-4 myInput text-left text-white" : "mr-4 ml-4 h-12 pr-4 myInput text-right text-white"}
                            placeholder={Number(localStorage.getItem("myValue")) === 0 ? "  Address  " : "العنوان" }  required/>
                        </div>

                        <div className=" mt-4">                            
  

                            {Number(localStorage.getItem("myValue")) === 0 ? 
                            <p className= {Number(localStorage.getItem("myValue")) === 0 ? "text-white md:ml-14 mb-2 text-sm text-left" : "text-white md:mr-14 mb-2 text-sm text-right" }
                            style={{color: "#ADADAD"}}> Notes (Optional) </p> :
                            
                            <div className="flex justify-end"> 

                            <p className= "text-white md:mr-14 mb-2 text-sm text-right md:-mr-0 mt-1" 
                            style={{color: "#6B6B6B", fontSize: "12px"}}> (اختياري) </p>
                            <p className= "text-white md:mr-14 mb-2  text-right ml-3" 
                            style={{color: "#ADADAD", fontSize: "18px"}}> رسالتك  </p>

                            </div>
                            }


                            <textarea type="submit" name="message" className= {Number(localStorage.getItem("myValue")) === 0 ? "md:mr-4 h-12 md:ml-14 pt-2 myInput text-left pl-4 text-white" : " md:ml-4 h-12 md:mr-14 pt-2 pr-4 text-white myInput text-right" }
                            style={{height: "130px", borderRadius: "10px"}} />
                            <p className= {Number(localStorage.getItem("myValue")) === 0 ? "text-white md:ml-14 mb-2 text-sm text-left" : "text-white md:mr-14 mb-2 text-sm text-right" }
                            style={{color: "#ADADAD", fontSize: "10px"}}> {Number(localStorage.getItem("myValue")) === 0 ? "  Enter additional information  " : "  أدخل معلومات إضافية " }</p>
                        </div>

                        <div className= {Number(localStorage.getItem("myValue")) === 0 ? " flex justify-center lg:justify-end mr-4 mb-10 lg:mb-0" :" flex justify-center lg:justify-start ml-4 " }>  
                        <button id="myButtonId"  href="mailto:info@whitetigerdrink.com" className="pl-10 pr-10  submitButton pt-1">
                            {Number(localStorage.getItem("myValue")) === 0 ? " SUBMIT " : " ارسال " }
                        </button>                          

                        </div>


                    </form>

                   

        
            </div>

            </div>
            </div>

            <Link className= {Number(localStorage.getItem("myValue")) === 0 ? "fixed lg:relative  link" : "fixed lg:relative  link"} style={{zIndex: "213353534421432"}} to={"/"}>
            <div className="flex justify-start lg:ml-20 lg:mt-5 lg:-mb-7 " style={{zIndex: "#8D8D8D", cursor: "pointer"}}>
                <img className="w-5 h-4 absolute mt-1" src={arrow} />
                <p style={{color: "white", zIndex: "342423"}} className="ml-7 backButton"> {Number(localStorage.getItem("myValue")) === 0 ? " BACK " : " رجوع " } </p>    
            </div>
            </Link>

            <div className= {Number(localStorage.getItem("myValue")) === 0 ? "flex justify-center bg-black pt-20  " : "flex justify-center bg-black pt-20" }>

                <p className="-mt-20 lg:-mt-20" style={{color: "#8D8D8D", zIndex: "342423"}}> جميع الحقوق محفوظة ® وايت تايجر 2024 </p>
            </div>
            
          
        </>
    )
}