import "./footer.css";
import footerImage from "../images/goldenLogo.png"

import linkTree from "../images/linkTree.png"
import facebookIcon from "../images/facebookIcon.png"
import instagramIcon from "../images/instagramIcon.png"
import youtubeIcon from "../images/youtubeIcon.png"
import snapchatIcon from "../images/snapchatIcon.png"
import tiktokIcon from "../images/tiktokIcon.png"
import twitterXIcon from "../images/twitterXIcon.png"

import { useRef } from "react";
import { useScrollContext } from '../ScrollContext';


export const Footer = () => {

    const { scrollToSection } = useScrollContext();

          
    const handleClick = (myNum) => {
        scrollToSection(myNum);

     
      }




    return (
        <div className="bg-black">

            <div style={{height: "fit-content", paddingBottom: '100px',}} className="bg-black grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 p-0">
                <div className={Number(localStorage.getItem("myValue")) === 0 ? " order-0 p-4 md:p-0 lg:ml-10" : "p-4 md:p-0 order-3 lg:mr-10"}>
                    <div className={Number(localStorage.getItem("myValue")) === 0 ? "flex justify-center md:justify-start mt-10 lg:mt-0" : "flex justify-center md:justify-end mt-10 lg:mt-0" }>
                    <img className="w-60 md:w-40 mt-28" src={footerImage} />
                    </div>
                    <p className={Number(localStorage.getItem("myValue")) === 0 ? "text-white lg:-ml-4 mt-4 footerPara text-sm" : "text-white mt-4 footerParaArabic text-sm" } style={{fontStyle: "italic"}}>
                    {Number(localStorage.getItem("myValue")) === 0 ? 
                    "Masaken Al-Bilad is the exclusive agent for White Tiger energy drinks. Established under Iraqi Companies Law No. 21 of 1997, the company specializes in trade, project implementation, contracting, and real estate investments." 
                    :
                    " مساكن البلاد الوكيل الحصري لمشروبات الطاقة وايت تايجر. تأسست الشركة بموجب قانون الشركات العراقي رقم 21 لسنة 1997، وتختص الشركة بالتجارة وتنفيذ المشاريع والمقاولات والاستثمارات العقارية."}
                       
                    
                    </p>

                </div>

                <div className={Number(localStorage.getItem("myValue")) === 0 ? "mt-28 md:mt-40 lg:ml-44 order-1" : "mt-28 md:mt-40 lg:mr-44 order-2"}>
                    <p style={{cursor: "pointer"}} className={Number(localStorage.getItem("myValue")) === 0 ? "text-white p-2 text-lg englishtext" : "text-white p-2 text-lg arabictext"} onClick={() => handleClick(0)}>{Number(localStorage.getItem("myValue")) === 0 ? " Home " : "الرئيسية"} </p>
                    <p style={{cursor: "pointer"}} className={Number(localStorage.getItem("myValue")) === 0 ? "text-white p-2 text-lg englishtext" : "text-white p-2 text-lg arabictext"} onClick={() => handleClick(4)}>{Number(localStorage.getItem("myValue")) === 0 ? " Gallery  " : "معرض الصور"} </p>
                    <p style={{cursor: "pointer"}} className={Number(localStorage.getItem("myValue")) === 0 ? "text-white p-2 text-lg englishtext" : "text-white p-2 text-lg arabictext"} onClick={() => handleClick(1)}>{Number(localStorage.getItem("myValue")) === 0 ? " About Us " : "من نحن"} </p>
                    <p style={{cursor: "pointer"}} className={Number(localStorage.getItem("myValue")) === 0 ? "text-white p-2 text-lg englishtext" : "text-white p-2 text-lg arabictext"} onClick={() => handleClick(5)}>{Number(localStorage.getItem("myValue")) === 0 ? " Contact Us " : "الاتصال بنا"} </p>

                </div>

                <div className={Number(localStorage.getItem("myValue")) === 0 ? "mt-28 md:mt-40 lg:ml-10 order-2" : "mt-28 md:mt-40 lg:mr-10 order-1" }>
                    <p className={Number(localStorage.getItem("myValue")) === 0 ? "text-white p-2 pb-0 -mb-2 text-lg englishtext" : "text-white p-2 pb-0 -mb-2 text-lg arabictext"}> +9647844000698 </p>
                    <p className={Number(localStorage.getItem("myValue")) === 0 ? "text-white p-2 pb-1 text-lg englishtext" : "text-white p-2 pb-1 text-lg arabictext"}> +9647744000698 </p>
                    <p className={Number(localStorage.getItem("myValue")) === 0 ? "text-white p-2 pb-0 -mb-2 text-lg englishtext" : "text-white p-2 pb-0 -mb-2 text-lg arabictext"  }>  
                    {Number(localStorage.getItem("myValue")) === 0 ? "Sat-Thu" : "السبت - الخميس" }
                    </p>
                    <p className={Number(localStorage.getItem("myValue")) === 0 ?"text-white p-2 text-lg englishtext" : "text-white p-2 text-lg arabictext"}>
                        {Number(localStorage.getItem("myValue")) === 0 ? "10:00 - 20:00 " : "20:00 - 10:00" }
                    </p>
                    
                    <div className={Number(localStorage.getItem("myValue")) === 0 ? "flex ml-2 mt-2 justify-center lg:justify-start" : "flex mr-2 mt-2 justify-center lg:justify-end"}>
                    <p className="text-white text-sm mb-1">{Number(localStorage.getItem("myValue")) === 0 ? "Social media" : "التواصل الاجتماعي" }</p>
                    
                    </div>
                    <div className={Number(localStorage.getItem("myValue")) === 0 ? "flex ml-2 mt-2 justify-center lg:justify-start" : "flex ml-2 mt-2 justify-center lg:justify-end"}>
                    <img style={{cursor: "pointer", marginTop: "2px"}} className=" h-5 mr-3" src={facebookIcon} onClick={() => window.location.assign("https://www.facebook.com/whitetiger.iq")} />

                    <img style={{cursor: "pointer"}} className=" h-6 mr-3" src={instagramIcon} onClick={() => window.location.assign("https://www.instagram.com/whitetiger.iq")} />

                    <img style={{cursor: "pointer"}} className=" h-4 mr-3 mt-1" src={youtubeIcon} onClick={() => window.location.assign("https://www.youtube.com/channel/UCgnJpagyQJDHrQLyHdPoiGg")} />

                    <img style={{cursor: "pointer"}} className=" h-6 mr-3" src={snapchatIcon} onClick={() => window.location.assign("https://accounts.snapchat.com/v2/welcome")} />

                    <img style={{cursor: "pointer"}} className=" h-6 mr-3" src={tiktokIcon} onClick={() => window.location.assign("https://www.tiktok.com/@whitetiger.iq")} />

                    <img style={{cursor: "pointer", marginTop: "2px"}} className=" h-5 mr-3" src={twitterXIcon} onClick={() => window.location.assign("https://twitter.com/whiitetigeer")} />
                    </div>
                   
                   
                </div>

                <div className={Number(localStorage.getItem("myValue")) === 0 ? "mt-28 md:mt-40 order-3" : "mt-60 md:mt-40  order-0" }>
                    <p className={Number(localStorage.getItem("myValue")) === 0 ? "text-white p-2 text-2xl englishtext" : "text-white p-2 text-2xl arabictext"}> 
                    {Number(localStorage.getItem("myValue")) === 0 ? "Address" :" العنوان" }
                    </p>

                    <p className={Number(localStorage.getItem("myValue")) === 0 ? "text-white p-2 lg:w-80 text-sm englishtext" : "text-white p-2 text-sm arabictext"}
                    style={{color: "#989898"}}>{Number(localStorage.getItem("myValue")) === 0 ? 
                    " Baghdad - Al-Mansour - Alamerat St. "
                    :
                    <>
                    <p> بغداد - المنصور - شارع الاميرات </p>
                     </> } </p>


                    <p className={Number(localStorage.getItem("myValue")) === 0 ? "text-white p-2 text-sm englishtext" : "text-white p-2 text-sm arabictext" } 
                    style={{color: "#989898"}}>  {Number(localStorage.getItem("myValue")) === 0 ? "TEL: 009647844000698" :" هاتف : 009647844000698 " }  </p>

                    <p className={Number(localStorage.getItem("myValue")) === 0 ? "text-white p-2 text-sm englishtext" : "text-white p-2 text-sm arabictext" } 
                    style={{color: "#989898"}}>  {Number(localStorage.getItem("myValue")) === 0 ? "E-mail: info@whitetigerdrink.com " :                    <>
                    <p>:البريد الالكتروني</p>
                    <p>info@whitetigerdrink.com</p>
                     </>}  </p>

                    
 
               

                </div>

            </div>
            <div className="flex justify-center pb-10">
            <p className="text-sm" style={{color: "#8D8D8D"}}>{Number(localStorage.getItem("myValue")) === 0 ? " White Tiger Drink ® All rights reserved 2024 " :" جميع الحقوق محفوظة ® وايت تايجر 2024 " }  </p>
            </div>
           
        </div>
    )
} 